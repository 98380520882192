
/*
Mixin for notification display.
Requires the following component properties:
project, currentUserRights, $route.params.id, $t
Param redirect is for NotificationRedirect.vue
*/

export default {
  methods: {
    createEvent(n, id, redirect = false) {
      //if (n.user_id === n.listener_id) return null;

      if (!redirect && !this.show_read && n.read_at) return null

      const action = `${n.type}/${n.operation}`;
      const prj = !this.project && n.project ? n.project.name : '';
      const addProject = prj == '' ? '' : prj + ': ';
      let pos_id = '';

      if (this.isPersonal && (action === 'task/done' || action === 'position/state_bhb')) return null;

      switch (action) {
        case 'comment/mention':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          if (n.note === 'INTERN' && !this.currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Intern sehen')) {
            return null
          }

          if (n.note === 'EXTERN' && !this.currentUserRights.includes('BHB - Vollbild_Kommentarfunktion Kunde sehen')) {
            return null
          }

          let title = this.$t('notifications.comment_desc', { user_name: n.user.name, listener_name: n.listener.name });
          let description = '';
          let route = '';

          if (n.position) {
            route = `/project/${n.project_id}/bhb/${n.position_id}`;
            description = prj + ' ' + n.position.key;

              if (!this.isPersonal) {
                title = this.$t('notifications.comment_mentioned_desc', { user_name: n.user.name, listener_name: n.listener.name });
                description = 'Position: ' + n.position.key;
              }
          }

          const notification = {
            action,
            title,
            description, //`${n.user.name} hat ${n.listener.name} in einem Kommentar erwähnt.`,
            route: route,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id,
            ctx: { comment_id: n.item_id }
          }

          if (n.comment) {
            if (n.comment.type === 'CHAT') {
              notification.route = `/project/${n.project_id}/chat`
            }

            // if (n.comment.type === 'TASK_COMMENT') {
            //   notification.route = `/project/${n.project_id}/tasks/${n.task_id}`
            // }
            notification.route = notification.route + '?comment_id=' + n.comment.id
          }
          return notification;

        case 'comment/newsfeed':
          return {
            action,
            title: this.$t('notifications.newsfeed_new'),
            description: n.note,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'material_additional/komm_bemerkung':
            if ( !redirect && n.user_id === this.currentUser.id ) return null;

            pos_id = n.additionals.id;
  
            return {
              action,
              title: addProject + this.$t('notifications.comment_comm', { user_name: n.user.name }), //"Bemerkung auf Kommissionierung",
              description: this.$t('notifications.comment_comm_desc', { user_name: n.user.name }), //`${n.user.name} hat die Kommissionierung kommentiert.`,
              route: `/project/${n.project_id}/picking-list/${pos_id}`,
              icon: 'fas fa-comment-dots',
              type: 'warning',
              time: n.created_at,
              read_at: n.read_at,
              id: n.id
            };
        case 'material_additional/purchase_bemerkung':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.comment_purchase', { user_name: n.user.name }), //"Bemerkung auf Einkauf",
            description: this.$t('notifications.comment_purchase_desc', { user_name: n.user.name }), //`${n.user.name} hat den Einkauf kommentiert.`,
            route: `/project/${n.project_id}/purchase`,
            icon: 'fas fa-comment-dots',
            type: 'warning',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'material_additional/lade_bemerkung':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.comment_loading'), //"Bemerkung auf der Ladeliste",
            description: this.$t('notifications.comment_loading_desc', { user_name: n.user.name }), //`${n.user.name} hat die Ladung kommentiert.`,
            route: `/project/${n.project_id}/loading-list`,
            icon: 'fas fa-comment-dots',
            type: 'warning',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'material_additional/rueck_bemerkung':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.comment_rueck', { user_name: n.user.name }), //"Bemerkung auf Rücklagerung",
            description: this.$t('notifications.comment_rueck_desc', { user_name: n.user.name }), //`${n.user.name} hat die Rücklagerung kommentiert.`,
            route: `/project/${n.project_id}/re-storage-list`,
            icon: 'fas fa-comment-dots',
            type: 'warning',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'material/komm_bemerkung':
            if ( !redirect && n.user_id === this.currentUser.id ) return null;

            pos_id = n.position_id !== null ? n.position_id : '';
  
            return {
              action,
              title: addProject + this.$t('notifications.comment_comm', { user_name: n.user.name }), //"Bemerkung auf Kommissionierung",
              description: this.$t('notifications.comment_comm_desc', { user_name: n.user.name }), //`${n.user.name} hat die Kommissionierung kommentiert.`,
              route: `/project/${n.project_id}/picking-list/${pos_id}`,
              icon: 'fas fa-comment-dots',
              type: 'warning',
              time: n.created_at,
              read_at: n.read_at,
              id: n.id
            };
        case 'material/purchase_bemerkung':
            if ( !redirect && n.user_id === this.currentUser.id ) return null;

            return {
              action,
              title: addProject + this.$t('notifications.comment_purchase', { user_name: n.user.name }), //"Bemerkung auf Einkauf",
              description: this.$t('notifications.comment_purchase_desc', { user_name: n.user.name }), //`${n.user.name} hat den Einkauf kommentiert.`,
              route: `/project/${n.project_id}/purchase`,
              icon: 'fas fa-comment-dots',
              type: 'warning',
              time: n.created_at,
              read_at: n.read_at,
              id: n.id
            };
        case 'material/lade_bemerkung':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.comment_loading'), //"Bemerkung auf der Ladeliste",
            description: this.$t('notifications.comment_loading_desc', { user_name: n.user.name }), //`${n.user.name} hat die Ladung kommentiert.`,
            route: `/project/${n.project_id}/loading-list`,
            icon: 'fas fa-comment-dots',
            type: 'warning',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'material/rueck_bemerkung':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.comment_rueck', { user_name: n.user.name }), //"Bemerkung auf Rücklagerung",
            description: this.$t('notifications.comment_rueck_desc', { user_name: n.user.name }), //`${n.user.name} hat die Rücklagerung kommentiert.`,
            route: `/project/${n.project_id}/re-storage-list`,
            icon: 'fas fa-comment-dots',
            type: 'warning',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'position/state_bhb':
          if (n.note === 'FREIGEGEBEN' && !this.currentUserRights.includes('notification.status-bhb.freigabe')) {
            return null
          }

          if (n.note === 'KORREKTUR' && !this.currentUserRights.includes('notification.status-bhb.korrektur')) {
            return null
          }

          if (n.note === 'HANDLUNG_PL' && !this.currentUserRights.includes('notification.status-bhb.handlung-pl')) {
            return null
          }

          if (n.note === 'LEER' && !this.currentUserRights.includes('notification.status-bhb.leer')) {
            return null
          }

          if (n.note === 'IN_PRODUKTION' && !this.currentUserRights.includes('notification.status-bhb.in-produktion')) {
            return null
          }

          if (n.note === 'MONTIERT' && !this.currentUserRights.includes('notification.status-bhb.montiert')) {
            return null
          }

          if (n.note === 'OFFEN' && !this.currentUserRights.includes('notification.status-bhb.offen')) {
            return null
          }

          if (n.note === 'ABGELEHNT' && !this.currentUserRights.includes('notification.status-bhb.ablehnen')) {
            return null
          }

          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action: action + '/' + n.note,
            title: addProject + this.$t('notifications.bhb_state', { key: n.position.key }) + ' ' + this.$t('message.bhbState.' + n.note), //`Neuer Zustand von ${n.position.key}: ${n.note}`,
            description: this.$t('notifications.bhb_state_desc', { user_name: n.user.name }), //`${n.user.name} hat den Zustand geändert.`,
            route: `/project/${n.project_id}/bhb/${n.position_id}`,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };

        case 'position/mounted':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.mounted', { key: n.position.key }), //"Montage von ${n.position.key}",
            description: this.$t('notifications.mounted_desc', { user_name: n.user.name }), //`${n.user.name} hat die Position als montiert gekennzeichnet.`,
            route: `/project/${n.project_id}/bhb/${n.position_id}`,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        case 'position/rel_business':
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: addProject + this.$t('notifications.rel_business', { key: n.position.key }), //`Wirtschaftliche Freigabe von ${n.position.key}`,
            description: this.$t('notifications.rel_business_desc', { key: n.position.key, user_name: n.user.name }), //`${n.user.name} hat die Position wirtschaftlich freigegeben.`,
            route: `/project/${n.project_id}/bhb/${n.position_id}`,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        // case 'task/create':
        //   if (!this.currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen) ')) {
        //     return null
        //   }

        //   if ( !redirect && n.user_id === this.currentUser.id ) return null;

        //   return {
        //     action,
        //     title: this.$t('notifications.task_new', { user_name: n.user.name }), //`Neue Aufgabe von ${n.user.name}`,
        //     description: `${n.note}\n${prj}`,
        //     route: n.project_id ? `/project/${n.project_id}/tasks/${n.task_id}` : `/tasks/${n.task_id}`,
        //     icon: 'fas fa-comment-dots',
        //     type: 'info',
        //     time: n.created_at,
        //     read_at: n.read_at,
        //     id: n.id
        //   };

        // case 'task/assign':
        //   if (!this.currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen) ')) {
        //     return null
        //   }

        //   if ( !redirect && n.user_id === this.currentUser.id ) return null;

        //   return {
        //     action,
        //     title: addProject + this.$t('notifications.task_assigned', { user_name: n.user.name }), //`Aufgabe wurde von ${n.user.name} neu zugeordnet`,
        //     description: n.note,
        //     route: n.project_id ? `/project/${n.project_id}/tasks/${n.task_id}` : `/tasks/${n.task_id}`,
        //     icon: 'fas fa-comment-dots',
        //     type: 'info',
        //     time: n.created_at,
        //     read_at: n.read_at,
        //     id: n.id
        //   };
        // case 'task/done':
        //   if (!this.currentUserRights.includes('Dashboard Projekt A_ToDo´s (projektbezogen) ')) {
        //     return null
        //   }

        //   if ( !redirect && n.listener_id !== this.currentUser.id ) return null;

        //   title = id ? this.$t('notifications.task_done_project', { user_name: n.user.name, prj }) : this.$t('notifications.task_done', { user_name: n.user.name, prj })

        //   return {
        //     action,
        //     title, //prj + ' ' + `Aufgabe wurde von ${n.user.name} erledigt`,
        //     description: n.note,
        //     route: n.project_id ? `/project/${n.project_id}/tasks/${n.task_id}` : `/tasks/${n.task_id}`,
        //     icon: 'fas fa-comment-dots',
        //     type: 'info',
        //     time: n.created_at,
        //     read_at: n.read_at,
        //     id: n.id
        //   };
        case 'project_person/create':
          if (!this.currentUserRights.includes('notification.project-person.create')) {
            return null
          }

          if ( !redirect && n.user_id === this.currentUser.id ) return null;
          if ( n.listener_id == this.currentUser.id ) return null;

          return {
            action,
            title: id ? this.$t('notifications.project_person_added_project', { project: prj, user_name: n.user.name, new_member_name: n.listener.name }) : this.$t('notifications.project_person_created', { project: prj, user_name: n.user.name }), //prj + ' ' + `Ein neues Teammitglied`,
            description: id ? n.project.name : '',
            route: `/project/${n.project_id}/edit`,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          };
        default:
          if ( !redirect && n.user_id === this.currentUser.id ) return null;

          return {
            action,
            title: this.$t('notifications.notification', { project: prj }), // prj + ' ' + "Benachrichtigung",
            description: this.$t('notifications.notification_desc', { user_name: n.user.name, type: n.type, operation: n.operation }), //`${n.user.name} hat etwas Interessantes getan (${n.type}/${n.operation}).`,
            icon: 'fas fa-comment-dots',
            type: 'info',
            time: n.created_at,
            read_at: n.read_at,
            id: n.id
          }
      }
    }
  }
}
