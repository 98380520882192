<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <div class="row">
        <div class="col-10">
          <h5 class="h3 mb-0">{{$t('message.notifications')}}</h5>
        </div>
        <div class="col-2 text-right">
          <el-tooltip :content="$t('message.notificationAllSeen')" placement="top">
            <base-button type="primary" icon size="sm" round @click="clear">
              <span class="btn-inner--icon">
                <i class="fas fa-minus p-0"></i>
              </span>
            </base-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <!-- Card body -->
    <div class="card-body">
      <el-collapse v-model="activeElements" v-if="isVisible && Object.keys(eventsGrouped).length > 0" accordion>
        <el-collapse-item v-for="eventGroup in Object.keys(eventsGrouped)" :title="`${$t('comment.action.' + eventGroup)} (${eventsGrouped[eventGroup].length})`" :name="eventGroup" :key="eventGroup">
          <div class="timeline timeline-one-side" data-timeline-content="axis" data-timeline-axis-style="dashed" v-if="eventsGrouped[eventGroup].length > 0">
            <div v-for="event in eventsGrouped[eventGroup]"
                :key="event.id"
                class="timeline-block"
                style="cursor: pointer"
                  @click="onNotificationClicked(event)"
                  >
                <span class="timeline-step" :class="`badge-${event.type}`">
                  <i :class="event.icon"></i>
                </span>
                <div class="timeline-content">
                  <div class="d-flex justify-content-between pt-1">
                    <div>
                      <span class="text-muted text-sm font-weight-bold">{{event.title}}</span>
                    </div>
                    <div class="text-right">
                      <small class="text-muted"><i class="fas fa-clock mr-1"></i>{{event.time | datetime}}</small><br>
                      <small class="text-muted" v-if="event.read_at"><i class="fas fa-eye mr-1"></i>{{event.read_at | datetime}}</small>
                      <small class="text-muted">
                        <a
                          @click.stop="markNotificationAsRead(event.id)"
                          href="#">{{ $t('message.read') }}</a>
                      </small>

                    </div>
                  </div>
                  <span class="pre-formatted">{{event.description}}</span>
                </div>
              </div>

          </div>
        </el-collapse-item>
      </el-collapse>
      <div v-else>{{$t('message.noEvents')}}</div>

    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import notifications from '@/util/notifications'

  function groupBy(objectArray, property) {
    return objectArray
      .reduce(function (acc, obj) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  export default {
    name: 'timeline-card',
    mixins: [notifications],
    props: {
      isVisible: {
        type: Boolean,
        default: false
      },
      isPersonal: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        show_read: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'project',
        'currentUserRights',
        'userNotifications',
        'allNotifications',
        'projectNotifications'
      ]),
      eventsGrouped() {
        const g = groupBy( this.notifications
                    .sort((a,b) => {
                            return new Date(b.created_at) - new Date(a.created_at);
                          })
                    .map(n => this.createEvent(n, this.$route.params.id))
                    .filter(e => e !== null && e.action != 'task/create'), 'action')
        return g
      },
      activeElements() {
        return Object.keys(this.eventsGrouped)[0]
      },
      notifications() {
        if (!this.isPersonal) {
          const pn = this.projectNotifications(this.$route.params.id);
          return pn;
        } else {
          const un = this.userNotifications();
          return un;
        }
      }
    },
    methods: {
      ...mapActions([
        'getNotifications',
        'clearNotifications'
      ]),
      async updateEvents() {
        return this.$store.dispatch("getNotifications", { user: this.currentUser })
          .then(notifications => this.$store.commit('globalNotifications', notifications))
      },
      async clear() {
        try {
          await this.$confirm(
            this.$t('notifications.clear.message'),
            this.$t('notifications.clear.title'),
            {
              confirmButtonText: this.$t("message.yes"),
              cancelButtonText: this.$t("message.no"),
              type: "warning"
            }
          )

          const ids = this.notifications.map(e => e.id)
          await this.clearNotifications(ids)
          return this.updateEvents()
        } catch (error) {

        }
      },
      async onNotificationClicked (item) {
        if (item.ctx) {
          this.$store.commit('searchCtx', item.ctx)
        }
        await this.markNotificationAsRead(item.id)
        this.$router.push(item.route)
      },
      async markNotificationAsRead (notificationId) {
        await this.clearNotifications([notificationId])
        return this.updateEvents()
      }
    },
    // beforeDestroy () {
    //   this.notifications = []
    // },
    mounted () {
      if (!this.isPersonal) {
        this.$store.dispatch("getNotifications", { project_id: this.$route.params.id } )
        .then(notifications => {
          this.$store.commit('globalNotifications', notifications)
        })
      } else {
        this.$store.dispatch("getNotifications", { user: this.currentUser } )
        .then(notifications => {
          this.$store.commit('globalNotifications', notifications)
        })
      }
    }
  }
</script>
<style>
.pre-formatted {
  white-space: pre;
}
</style>
