<template>
  <div class="project-nav-bar" v-if="project">
    <div class="pos-f-t">
      <div class="collapse" id="navbarToggleExternalContent">
        <div class="bg-white p-4">
          <ul class="navbar-nav">
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}`"
            exact
            tag="li"
          >
            <a class="nav-link">{{ $t("message.dashboard") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/edit`"
            exact
            tag="li"
            v-if="
              currentUserRights.includes(
                'Dashboard Projekt A_Bearbeitung der Projektdaten'
              )
            "
          >
            <a class="nav-link">{{ $t("message.projectFacts") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/data`"
            tag="li"
            v-if="
              currentUserRights.includes('Dateneingabe_Ansicht') &&
              hasFunction('D')
            "
          >
            <a class="nav-link">{{ $t("message.dataInput") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/bhb`"
            tag="li"
            v-if="
              hasFunction('B') &&
              currentUserRights.includes('project.bhb.isvisible')
            "
          >
            <a class="nav-link">{{ $t("message.bhb") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/chat`"
            tag="li"
            v-if="
              currentUserRights.includes('project.chat.cansee') &&
              hasFunction('C')
            "
          >
            <a class="nav-link">{{ $t("message.chat") }}</a>
          </router-link>
          <apa-dropdown class="nav-item" tag="li" v-if="additionlLinks.length">
            <div class="nav-link dropdown-toggle">{{ $t("message.more") }}</div>
            <template v-slot:dropdown>
              <router-link
                class="dropdown-item"
                exact
                :to="link.url"
                :key="link.id"
                v-for="link in additionlLinks"
                >{{ link.label }}</router-link
              >
            </template>
          </apa-dropdown>
        </ul>
        </div>
      </div>
     
    </div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="navbar-brand" style="color: #000">
        {{ project.key }} - {{ project.name }}
      </div>
      <button
        class="navbar-toggler btn btn-primary"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo03"
        aria-controls="navbarTogglerDemo03"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleNav"
      >
        <span class="navbar-toggler-icon"> </span>
      </button>

      <div
        class="inline-navbar navbar-collapse justify-content-end"
      >
        <ul class="navbar-nav">
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}`"
            exact
            tag="li"
          >
            <a class="nav-link">{{ $t("message.dashboard") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/edit`"
            exact
            tag="li"
            v-if="
              currentUserRights.includes(
                'Dashboard Projekt A_Bearbeitung der Projektdaten'
              )
            "
          >
            <a class="nav-link">{{ $t("message.projectFacts") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/data`"
            tag="li"
            v-if="
              currentUserRights.includes('Dateneingabe_Ansicht') &&
              hasFunction('D')
            "
          >
            <a class="nav-link">{{ $t("message.dataInput") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/bhb`"
            tag="li"
            v-if="
              hasFunction('B') &&
              currentUserRights.includes('project.bhb.isvisible')
            "
          >
            <a class="nav-link">{{ $t("message.bhb") }}</a>
          </router-link>
          <router-link
            class="nav-item"
            :to="`/project/${$route.params.id}/chat`"
            tag="li"
            v-if="
              currentUserRights.includes('project.chat.cansee') &&
              hasFunction('C')
            "
          >
            <a class="nav-link">{{ $t("message.chat") }}</a>
          </router-link>
          <apa-dropdown class="nav-item" tag="li" v-if="additionlLinks.length">
            <div class="nav-link dropdown-toggle">{{ $t("message.more") }}</div>
            <template v-slot:dropdown>
              <router-link
                class="dropdown-item"
                exact
                :to="link.url"
                :key="link.id"
                v-for="link in additionlLinks"
                >{{ link.label }}</router-link
              >
            </template>
          </apa-dropdown>
        </ul>
      </div>
    </nav>
    <div class="project_subnav tablet-hide">
      <div class="project_infos">
        <router-link :to="`/project/${$route.params.id}`">
          <el-badge
            :value="unreadProjectNotifications($route.params.id).length"
            class="project-notification-item"
            v-if="
              unreadProjectNotifications($route.params.id).length > 0 &&
              currentUserRights.includes(
                'Dashboard Projekt A_Benachrichtigungsfeld'
              )
            "
          >
            <i class="fas fa-bell faa-shake animated"></i>
          </el-badge>
        </router-link>
        <i v-if="project.state == 'ARCHIVED'" class="fas fa-archive ml-1"></i>
        <project-header></project-header>
      </div>
      <div
        class="project_addresses_selection"
        v-if="!$route.meta.hideAddressSelector"
      >
        <el-select class="select-primary" v-model="projectAddress" filterable>
          <el-option
            :label="$t('message.allProjectAddresses')"
            :value="0"
          ></el-option>
          <el-option
            v-for="option in projectAddresses"
            class="select-primary"
            :value="option.address.id"
            :label="option.address.name"
            :key="option.address.id"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import ApaDropdown from "@/components/ApaDropdown/ApaDropdown";
import ProjectHeader from "./ProjectHeader";
import $ from "jquery";

const additionlLinks = [
  {
    url: (projectId) => `/project/${projectId}/docs`,
    label: "message.docs",
    requireRight: "Dokumente_Zugriff APA",
  },
  {
    url: (projectId) => `/project/${projectId}/offer`,
    label: "message.offer",
    requireFunction: "A",
    requireRight: "Angebot_Ansicht",
  },
  {
    url: (projectId) => `/project/${projectId}/assembly-planning`,
    label: "message.assembly",
    requireFunction: "M",
    requireRight: "Montageplanung_Ansicht",
  },
  {
    url: (projectId) => `/project/${projectId}/purchase`,
    label: "message.orderList",
    requireFunction: "E",
    requireRight: "Einkaufsliste_Ansicht",
  },
  {
    url: (projectId) => `/project/${projectId}/picking-list`,
    label: "message.pickingList",
    requireFunction: "K",
    requireRight: "project.kommissionierungsliste.isvisible",
  },
  {
    url: (projectId) => `/project/${projectId}/loading-list`,
    label: "message.loadingList",
    requireFunction: "L",
    requireRight: "project.ladeliste.isvisible",
  },
  {
    url: (projectId) => `/project/${projectId}/re-storage-list`,
    label: "message.reStorage",
    requireFunction: "U",
    requireRight: "project.rueckeinlagerungsliste.isvisible",
  },
  /*
  {
    url: (projectId) => `/project/${projectId}/tasks`,
    label: "message.tasks",
    requireFunction: "T",
    requireRight: "Dashboard Projekt A_ToDo´s (projektbezogen) ",
  },
  */
  {
    url: (projectId) => `/project/${projectId}/info-pool`,
    label: "message.infoPool",
    requireFunction: "I",
    requireRight: "Informationspool_Ansicht eigene Projekte",
  },
  {
    url: (projectId) => `/project/${projectId}/debriefing`,
    label: "message.debriefing",
    requireFunction: "R",
    requireRight: "project.debriefing.isvisible",
  },
  {
    url: (projectId) => `/project/${projectId}/archive`,
    label: "message.archive",
    requireRight: "project.archive.isvisible",
  },
];

export default {
  name: "ProjectNavBar",
  components: {
    ApaDropdown,
    ProjectHeader,
  },
  computed: {
    ...mapGetters([
      "project",
      "projectAddresses",
      "currentProjectAddress",
      "currentUserRights",
      "unreadProjectNotifications",
    ]),
    additionlLinks() {
      const { id } = this.project;
      return additionlLinks
        .filter(({ requireFunction }) =>
          requireFunction ? this.hasFunction(requireFunction) : true
        )
        .filter(({ requireRight }) =>
          requireRight ? this.currentUserRights.includes(requireRight) : true
        )
        .map((link) => ({
          ...link,
          url: link.url(id),
          label: this.$t(link.label),
        }));
    },

    projectAddress: {
      get: function () {
        return this.currentProjectAddress;
      },
      set: function (newValue) {
        this.$store.commit("SET_CURRENT_PROJECT_ADDRESS", newValue);
      },
    },
  },
  methods: {
    hasFunction(func) {
      return this.project.functions.includes(func);
    },
    toggleNav() {
      $(".collapse").toggle();
    },
  },
};
</script>
<style>
.project-nav-bar .navbar {
  padding-left: 35px;
}

.project_subnav {
  background: #f7f7f7;
  padding: 14px 22px 14px 35px;
  display: flex;
  justify-content: space-between;
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #9f2f37;
}

.project-nav-bar .navbar-brand {
  white-space: normal;
}
.project-nav-bar .navbar-collapse {
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .tablet-hide {
    display: none;
  }
}

@media (max-width: 991px) {
  .project-nav-bar .navbar-toggler {
    padding-bottom: 6px;
    padding-top: 6px;
    background-color: #9f2f37;
    border-color: #9f2f37;

  }


  .project-nav-bar .inline-navbar{
    display: none;
  }


  .project-nav-bar .navbar {
    font-size: 1em;
    flex-wrap: nowrap;
  }
  .project-nav-bar .navbar-brand {
    font-size: 1rem;
  }
}

@media (max-width: 575px) {
  .project-nav-bar .navbar {
    padding-left: 1rem;

  }

  .project_subnav {
    padding: 14px 1rem;
    display: block;
  }
  .project_subnav .project_addresses_selection {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
